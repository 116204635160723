import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "button"]
  static values = {
    signupRating: { type: Number, default: 0 },
    signupRatingTier: { type: Number, default: 0 },
  }

  connect() {
    window.ahoy.track("shipping-friction",
      {
        step: $("#customer_form").attr("data-step"),
        step_name: $("#customer_form").attr("data-step-name"),
        signup_rating: this.signupRatingValue,
        signup_rating_tier: this.signupRatingTierValue
      }
    )

    const form = $(this.element).find("form")
    const button = $(this.buttonTarget)
    form.on("input", function() {
      if (form[0].checkValidity()) {
        button.removeAttr("disabled")
      } else {
        button.attr("disabled", "")
      }
    })
  }

  continue(event) {
    event.preventDefault()
    event.stopPropagation()

    const code = $(this.inputTarget).val()

    if (code.toLowerCase() === "pink") {
      window.sendGA4Event({
        event: "friction_form_was_submitted",
        code: code
      })

      $(this.element).modal("hide")
    }
  }
}
