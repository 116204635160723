import { Controller } from "@hotwired/stimulus"

// Scrolling for the quiz form
// This is currently used only for Quiz steps to bring form into view after turbo updates step
// TODO: once upgraded to Turbo 7.3,
// can be replaced by using autoscroll attribute on turbo frame
export default class extends Controller {
  connect () {
    if (!$("body.test").length) {
      const menuHeight = $("nav.site-navbar.sticky-top").css("height")
      const element = $(this.element)
      $("html, body").animate({
        scrollTop: element.offset().top - element.height() - menuHeight
      }, 300, function() {
        let invalidFields = $(".is-invalid")

        if (invalidFields.length) {
          invalidFields.first()[0].scrollIntoView({behavior: "smooth", block: "center"})
        }

        invalidFields = $(".base-errors")

        if (invalidFields.length) {
          invalidFields.first()[0].scrollIntoView({behavior: "smooth", block: "center"})
        }
      })
    }
  }
}



