/* global fbq */

import { Controller } from "@hotwired/stimulus"

window.fbqInitialized = window.fbqInitialized || false

export default class extends Controller {
  static values = {
    pixelId: String,
    event: String,
    customerHashed: Object,
    subscriptionId: String,
    price: {type: String, default: "102.37"},
    currency: {type: String, default: "USD"}
  }

  connect() {
    if (!this.pixelIdValue) {
      console.error("PixelController missing pixelIdValue")
      return
    }

    if (!this.eventValue) {
      console.error("PixelController missing eventValue!")
      return
    }

    // To prevent "Duplicate Pixel ID" error when running fbqInit more than once
    if (!window.fbqInitialized) {
      this.fbqInit()
      window.fbqInitialized = true
    }

    this.sendEvent(this.eventValue, this.customerHashedValue)
  }

  fbq() {
    if (window.nwApp.isProduction) {
      fbq.apply(null, arguments)
    } else {
      console.log("pixel fbq", ...arguments)
    }
  }

  fbqInit() {
    this.fbq("init", this.pixelIdValue, this.customerHashedValue)
  }

  sendEvent(eventName, customData) {
    let eventParams

    switch (eventName) {
    case "Purchase":
      eventParams = {
        content_name: "Subscription",
        subscription_id: this.subscriptionIdValue,
        value: this.priceValue,
        currency: this.currencyValue
      }
      this.nwPixel("Purchase", eventParams)
      break
    case "AddToCart":
      this.fbqInit()
      this.nwPixel("AddToCart", {value: this.priceValue, currency: this.currencyValue})
      break
    default:
      this.nwPixel(eventName, customData)
    }
  }

  // Copied from https://stackoverflow.com/a/8809472.
  generateUUID() {
  // Public Domain/MIT
    let d = new Date().getTime() // Timestamp
    let d2 = (performance && performance.now && performance.now() * 1000) || 0
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 // Random number between 0 and 16
      if (d > 0) {
      //Use timestamp until depleted
        r = (d + r) % 16 | 0
        d = Math.floor(d / 16)
      } else {
      // Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0
        d2 = Math.floor(d2 / 16)
      }
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16)
    })
  }

  /*
    This function encapsulates Facebook tracking.

    Traditionally, it has been enough for Facebook to store 3rd-party cookies to
    track people, but some browsers are starting to expire them shortly. As a
    workaround, Facebook manages to store 1st-party cookies for redundancy, so
    you can send the data server-side using the Conversions API.

    So, basically, we send each tracking request twice, one using JavaScript,
    and another one using the Conversions API. If the 3rd-party cookie has been
    expired by the browser, the 1st-party one may still be good. That way you
    ensure data associated to the event is as complete as possible.

    The event ID allows Facebook to know both events are the same. See
      https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events
  */
  nwPixel(eventName, customData) {
    const eventID = this.generateUUID()

    this.fbq("track", eventName, customData, { eventID: eventID })
    this.sendServerEvent(eventName, eventID, customData)
  }

  sendServerEvent(eventName, eventID, customData) {
    if (window.nwApp.isProduction) {
      // Conversion API
      // See https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events
      $.get("/pixel", {
        event_name: eventName,
        event_id: eventID,
        custom_data: customData,
      })
    } else {
      console.log("/pixel", ...arguments)
    }
  }
}
