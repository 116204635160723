import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { reloadInterval: Number, reloadUrl: String }
  static targets = ["turboFrame", "turboFrameLoadingCompleted"]

  turboFrameTargetConnected() {
    this.schedulePolling()
  }
  
  // The presence of this target signals polling must stop
  turboFrameLoadingCompletedTargetConnected() {
    this.stopPolling()
  }

  disconnect() {
    this.stopPolling()
  }

  schedulePolling() {
    const reloadInterval = this.reloadIntervalValue
    const controllerContext = this

    window.pollIntervalObject = setInterval(() => controllerContext.reloadTurboFrame(), reloadInterval)
  }

  stopPolling() {
    clearInterval(window.pollIntervalObject)
  }

  reloadTurboFrame() {
    if (this.hasTurboFrameLoadingCompletedTarget) {
      return
    }

    if (this.turboFrameTarget.hasAttribute("src")) {
      this.turboFrameTarget.reload()
    } else {
      this.turboFrameTarget.setAttribute("src", this.reloadUrlValue)
    }
  }
}
