import { Controller } from "@hotwired/stimulus"

// Timer controller
// Ex:
// <div data-controller="timer" data-timer-time-value="60">
//   <span data-timer-target="label"></span>
//   <span data-timer-target="value"></span>
// </div>
export default class extends Controller {
  timer
  secondsLeft

  static values = {
    time: Number, // time in seconds
    stoppable: Boolean, // whether the timer can be stopped
    timeoutCallback: String, // name of the function to call when the timer reaches 0
    // whether to use a simple format (e.g. 1:00) or a more complex one (e.g. 1 day 1:00)
    simpleFormat: { type: Boolean, default: false }
  }
  static targets = ["value", "label"]

  connect () {
    if (this.stoppableValue) {
      this.element.addEventListener("keypress", this.onKeyPress.bind(this))
    }

    this.secondsLeft = this.timeValue

    if (this.secondsLeft) {
      this.render()
      this.startTimer()
    }
  }

  disconnect () {
    this.element.removeEventListener("keypress", this.onKeyPress.bind(this))
    this.stopTimer()
  }

  render () {
    this.valueTarget.textContent = this.secondsToTimeString(this.secondsLeft)
    this.labelTarget.textContent = this.getLabel()
  }

  startTimer () {
    this.timer = setInterval(() => {
      this.secondsLeft -= 1

      this.render()

      if (this.secondsLeft <= 0) {
        if (this.timeoutCallbackValue) {
          if (window[this.timeoutCallbackValue]) {
            window[this.timeoutCallbackValue]()
          } else {
            eval(this.timeoutCallbackValue)
          }
        }

        this.stopTimer()
      }
    }, 1000)
  }

  stopTimer () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  onKeyPress (event) {
    if (event.key === "Enter") {
      event.preventDefault()
      this.onClick()
    }
  }

  onClick () {
    this.timer ? this.stopTimer() : this.startTimer()
  }

  secondsToTimeString (value) {
    let minutes = parseInt(value / 60, 10) || 0
    let seconds = parseInt(value % 60, 10) || 0
    let hours = parseInt(minutes / 60, 10) || 0
    let days = parseInt(hours / 24, 10) || 0
    hours = parseInt(hours % 24, 10) || 0
    minutes = parseInt(minutes % 60, 10) || 0

    const labelDays = days > 0 ? days + " day " : ""
    const labelHours = hours > 0 ? (hours < 10 ? "0" + hours : hours) + ":" : ""
    const labelMinutes = minutes < 10 ? "0" + minutes : minutes
    const labelSeconds = seconds < 10 ? "0" + seconds : seconds

    if (this.simpleFormatValue && (days > 0 || hours > 0)) {
      return (labelDays + labelHours + labelMinutes)
    } else {
      return (labelDays + labelHours + labelMinutes + ":" + labelSeconds)
    }
  }

  getLabel () {
    if (this.simpleFormatValue) {
      if (this.secondsLeft >= 86400) {
        return "Days"
      } else if (this.secondsLeft >= 3600) {
        return "Hours"
      }
    }

    return this.secondsLeft > 60 ? "Minutes" : "Seconds"
  }
}
