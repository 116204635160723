/**
 * Gets the current user ID.
 */
export function getCurrentUserId() {
  return $("body").data("nw-current-user-id")
}

/**
 * Re-Load tooltips
 */
export function loadTooltips() {
  $('[data-toggle="tooltip"]').tooltip()
}

/**
 * Colors
 */
export function adjustColour(referenceColor, element) {
  element.css("color", "#" + contrastingColor(colorNameToHex(referenceColor)))
}

export function contrastingColor(color) {
  return (luma(color) >= 165) ? "000" : "fff"
}

function luma(color) { // color can be a word, a hx string or an array of RGB values 0-255
  const rgb = (typeof color === "string") ? hexToRgb(color) : color
  return (0.2126 * rgb[0]) + (0.7152 * rgb[1]) + (0.0722 * rgb[2]) // SMPTE C, Rec. 709 weightings
}

function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? [ parseInt(result[1], 16), //r
    parseInt(result[2], 16), //g
    parseInt(result[3], 16) ] //b
    : [0, 0, 0]
}

const definedColorNames = {
  "other": "#000",
  "stripes": "#fff",
  "animal_print": "#000",
  "florals": "#fff",
  "abstract": "#000",
  "solids": "#fff",
  "aliceblue": "#f0f8ff",
  "antiquewhite": "#faebd7",
  "aqua": "#00ffff",
  "aquamarine": "#7fffd4",
  "azure": "#f0ffff",
  "beige": "#f5f5dc",
  "bisque": "#ffe4c4",
  "black": "#000000",
  "blanchedalmond": "#ffebcd",
  "blue": "#0000ff",
  "blueviolet": "#8a2be2",
  "brown": "#a52a2a",
  "bronze": "#a52a2e",
  "burlywood": "#deb887",
  "cadetblue": "#5f9ea0",
  "chartreuse": "#7fff00",
  "chocolate": "#d2691e",
  "coral": "#ff7f50",
  "cornflowerblue": "#6495ed",
  "cornsilk": "#fff8dc",
  "crimson": "#dc143c",
  "cyan": "#00ffff",
  "darkblue": "#00008b",
  "darkcyan": "#008b8b",
  "darkgoldenrod": "#b8860b",
  "darkgray": "#a9a9a9",
  "darkgreen": "#006400",
  "darkkhaki": "#bdb76b",
  "darkmagenta": "#8b008b",
  "darkolivegreen": "#556b2f",
  "darkorange": "#ff8c00",
  "darkorchid": "#9932cc",
  "darkred": "#8b0000",
  "darksalmon": "#e9967a",
  "darkseagreen": "#8fbc8f",
  "darkslateblue": "#483d8b",
  "darkslategray": "#2f4f4f",
  "darkturquoise": "#00ced1",
  "darkviolet": "#9400d3",
  "deeppink": "#ff1493",
  "deepskyblue": "#00bfff",
  "dimgray": "#696969",
  "dodgerblue": "#1e90ff",
  "firebrick": "#b22222",
  "floralwhite": "#fffaf0",
  "forestgreen": "#228b22",
  "fuchsia": "#ff00ff",
  "gainsboro": "#dcdcdc",
  "ghostwhite": "#f8f8ff",
  "gold": "#ffd700",
  "goldenrod": "#daa520",
  "gray": "#808080",
  "green": "#008000",
  "greenyellow": "#adff2f",
  "honeydew": "#f0fff0",
  "hotpink": "#ff69b4",
  "indianred ": "#cd5c5c",
  "indigo": "#4b0082",
  "ivory": "#fffff0",
  "khaki": "#f0e68c",
  "lavender": "#e6e6fa",
  "lavenderblush": "#fff0f5",
  "lawngreen": "#7cfc00",
  "lemonchiffon": "#fffacd",
  "lightblue": "#add8e6",
  "lightcoral": "#f08080",
  "lightcyan": "#e0ffff",
  "lightgoldenrodyellow": "#fafad2",
  "lightgrey": "#d3d3d3",
  "lightgreen": "#90ee90",
  "light_green": "#90ee90",
  "lightpink": "#ffb6c1",
  "lightsalmon": "#ffa07a",
  "lightseagreen": "#20b2aa",
  "lightskyblue": "#87cefa",
  "lightslategray": "#778899",
  "lightsteelblue": "#b0c4de",
  "lightyellow": "#ffffe0",
  "lime": "#00ff00",
  "limegreen": "#32cd32",
  "linen": "#faf0e6",
  "magenta": "#ff00ff",
  "maroon": "#800000",
  "mediumaquamarine": "#66cdaa",
  "mediumblue": "#0000cd",
  "mediumorchid": "#ba55d3",
  "mediumpurple": "#9370d8",
  "mediumseagreen": "#3cb371",
  "mediumslateblue": "#7b68ee",
  "mediumspringgreen": "#00fa9a",
  "mediumturquoise": "#48d1cc",
  "mediumvioletred": "#c71585",
  "midnightblue": "#191970",
  "mintcream": "#f5fffa",
  "mistyrose": "#ffe4e1",
  "moccasin": "#ffe4b5",
  "navajowhite": "#ffdead",
  "navy": "#000080",
  "oldlace": "#fdf5e6",
  "olive": "#808000",
  "olivedrab": "#6b8e23",
  "orange": "#ffa500",
  "orangered": "#ff4500",
  "orchid": "#da70d6",
  "palegoldenrod": "#eee8aa",
  "palegreen": "#98fb98",
  "paleturquoise": "#afeeee",
  "palevioletred": "#d87093",
  "papayawhip": "#ffefd5",
  "peachpuff": "#ffdab9",
  "peru": "#cd853f",
  "pink": "#ffc0cb",
  "plum": "#dda0dd",
  "powderblue": "#b0e0e6",
  "purple": "#800080",
  "red": "#ff0000",
  "rosybrown": "#bc8f8f",
  "royalblue": "#4169e1",
  "saddlebrown": "#8b4513",
  "salmon": "#fa8072",
  "sandybrown": "#f4a460",
  "seagreen": "#2e8b57",
  "seashell": "#fff5ee",
  "sienna": "#a0522d",
  "silver": "#c0c0c0",
  "skyblue": "#87ceeb",
  "slateblue": "#6a5acd",
  "slategray": "#708090",
  "snow": "#fffafa",
  "springgreen": "#00ff7f",
  "steelblue": "#4682b4",
  "tan": "#d2b48c",
  "teal": "#008080",
  "thistle": "#d8bfd8",
  "tomato": "#ff6347",
  "turquoise": "#40e0d0",
  "violet": "#ee82ee",
  "wheat": "#f5deb3",
  "white": "#ffffff",
  "whitesmoke": "#f5f5f5",
  "yellow": "#ffff00",
  "yellowgreen": "#9acd32",
  "darkgrey": "#a9a9a9",
  "darkslategrey": "#2f4f4f",
  "dimgrey": "#696969",
  "grey": "#808080",
  "lightgray": "#d3d3d3",
  "lightslategrey": "#778899",
  "slategrey": "#708090"}
// color list from http://stackoverflow.com/q/1573053/731179  with added gray/gray
export function colorNameToHex(colorName, returnUnresolved) {
  let hexRGB
  if (definedColorNames[colorName.toLowerCase()] !== undefined) {
    hexRGB = definedColorNames[colorName.toLowerCase()]
    // to keep unresolved strings set flag returnUnresolved to true
  } else {
    if (returnUnresolved) {
      hexRGB = this
    } else {
      hexRGB = undefined
    }
  }
  return hexRGB
}

// Which input should be focused?
export function decideFocusedInput() {
  const focusableForms = document.getElementsByClassName("focusable-form")

  if (focusableForms.length > 0) {
    // When there are open focusable forms, don't try to focus on the scan input fields!
    return
  }

  const scanInput = document.getElementById("scan")
  if (scanInput) {
    scanInput.focus()
    return
  }

  const panel = $("#nw-nav-shipments-search-panel")
  if (panel.get().length === 0) {
    // No search panel found on current page
    return
  }
  panel.find("#smart_id").focus()
}

export function showDatatablesFixedHeaders(tabId) {
  Object.keys(window.dataTables).forEach(function (key) {
    const table = window.dataTables[key]

    // show fixed header for the current tab only
    if (key === tabId) {
      table.fixedHeader.enable()
    } else {
      table.fixedHeader.disable()
    }
  })
}

// http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
export const FORM_VALIDATION_EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/ // eslint-disable-line
// TODO: reuse both RegExp from nadinewest_customer_concern.rb
export const EMAIL_REGEX = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i
export const NAME_REGEXP = /^[A-Za-z][A-Za-z'-.]+([ A-Za-z][A-Za-z'-.]+)*$/i

export function shiftYears(years) {
  const today = new Date()

  return today.setFullYear(today.getFullYear() - years)
}
