import { Controller } from "@hotwired/stimulus"

// Toggles the text content between two values when an action is triggered.
// Example: Initially displays "More" (initialText). When toggled, changes to "Less" (alternateText).
export default class extends Controller {
  static values = {
    initialText: String,
    alternateText: String
  }
  static targets = ["text"];

  toggle() {
    if (this.textTarget.innerText == this.initialTextValue) {
      this.textTarget.innerText = this.alternateTextValue
    } else {
      this.textTarget.innerText = this.initialTextValue
    }
  }
}
