import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    if (this.urlValue) {
      // TODO: why does the following frame attributes not work with latest Turbo?
      //       autoscrol: true, data: {'autoscroll-behavior': 'smooth', 'autoscroll-block': 'start'}
      // Using scrollIntoView instead:
      this.element.scrollIntoView({behavior: "smooth", block: "center"})

      const url = this.urlValue
      setTimeout(function() {
        // The following visit produces 2 GET requests and shows the last one.
        // This in turn *ucks up any session variable handling.
        // Turbo.visit(url, {action: "replace"})
        // Setting href does not create two requests!
        window.location.href = url
        // NOTE: after trying out on other parts of code that also do a notify and reload,
        //       was not able to reproduce such a double GET.
      }, 300)
    }
  }
}
