import { Controller } from "@hotwired/stimulus"

// Scroll-control visibility of a sticky button on top of page
export default class extends Controller {
  static targets = ["ctaButton"];

  connect() {
    const root = document.documentElement
    const style = getComputedStyle(root)
    const breakpointLarge = style.getPropertyValue("--breakpoint-lg")
    const breakpointSmall = style.getPropertyValue("--breakpoint-sm")

    const stickyButton = $(this.ctaButtonTarget)
    const initialYOffsetTop = stickyButton.offset().top
    let yOffsetTop = (yOffsetTop = $("#navbar").height() * 2)
    const fixedPositionThreshold = initialYOffsetTop - yOffsetTop

    const tabletMediaQuery = window.matchMedia(
      `(max-width: ${breakpointLarge})`,
    )
    const phoneMediaQuery = window.matchMedia(
      `(max-width: ${breakpointSmall})`,
    )
    const experimentFlag = stickyButton.hasClass("suburbs")

    const mobileView = phoneMediaQuery.matches || tabletMediaQuery.matches

    $(window).scroll(function () {
      const scrollTop = $(window).scrollTop()
      // Member scrolls up, make the button normal again
      if (scrollTop < fixedPositionThreshold) {
        stickyButton.removeClass("position-fixed shadow-lg")
        if (mobileView || experimentFlag) {
          stickyButton.css("left", "0")
          stickyButton.css("transform", "translateX(0%)")
        }
      }

      // Member scrolls down, make the button sticky
      if (scrollTop > fixedPositionThreshold) {
        stickyButton.css("top", yOffsetTop)
        stickyButton.addClass("position-fixed shadow-lg")
        if (mobileView || experimentFlag) {
          stickyButton.css("left", "50%")
          stickyButton.css("transform", "translateX(-50%)")
        }
      }
    })
  }
}
