import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { changeOnConnect: Boolean, default: false }

  connect() {
    if (this.changeOnConnectValue) {
      this.formHasChanged()
    }

    const that = this
    this.element.addEventListener("input", function(event) {
      if (event.target.value !== "") {
        that.formHasChanged()
      }
    })
  }

  confirmExit(event) {
    if (this.hasFormChanged()) {
      if (event.type == "turbolinks:before-visit") {
        if (!window.confirm("Discard changes?")) {
          event.preventDefault()
        }
      } else {
        event.returnValue = "Leaving with unsaved changes?"
        return event.returnValue
      }
    }
  }

  formHasChanged(event) {
    this.setChanged("true")
  }

  submit(event) {
    this.setChanged("false")
  }

  setChanged(changed) {
    this.data.set("changed", changed)
  }

  hasFormChanged() {
    return this.data.get("changed") == "true"
  }
}
