import { Controller } from "@hotwired/stimulus"

// Check if either "Keep" or "Return" is selected for all items to enable the submit button
// If not, show the validation message
export default class extends Controller {
  static targets = [
    "customerStatus",
    "validationMessage",
    "submitButton"
  ]

  connect () {
    this.update()
  }

  update() {
    const allSelected = this.allHaveSelectedRadio(this.customerStatusTargets)
    this.validationMessageTarget.hidden = allSelected
    this.submitButtonTarget.disabled = !allSelected
  }

  allHaveSelectedRadio(elements) {
    return elements.every(item =>
      Array.from(item.querySelectorAll('input[type="radio"]')).some(radioButton => radioButton.checked)
    )
  }
}
