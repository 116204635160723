import { Controller } from "@hotwired/stimulus"

// Bootstrap datepicker
export default class extends Controller {
  static values = {
    // calendar && birthdate - totally made these up
    // - calendar: the default day view
    // - birthdate: the default year view with permitted dates being -18 years goind back
    viewMode: { type: String, default: "calendar" },
  }

  connect() {
    const commonOptions = {
      zIndexOffset: 100
    }

    const birthdateViewModeOptions = {
      viewMode: "years",
      startView: "years",
      endDate: "-18y",
      maxViewMode: "years",
      minViewMode: "months",
      format: "mm/yyyy",
      ...commonOptions
    }

    const calendarViewModeOptions = {
      format: "yyyy-mm-dd",
      ...commonOptions
    }

    let activeViewModeOptions = calendarViewModeOptions

    if (this.viewModeValue == "birthdate") {
      activeViewModeOptions = birthdateViewModeOptions
    }

    $(this.element).datepicker(activeViewModeOptions)
  }

  disconnect() {
    $(this.element).datepicker("destroy")
  }
}
