import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    event: String,
    dataHashed: Object,
  }

  connect() {
    // keeping the same safety checks below for now as in pixel_controller.js. Should refactor both
    if (!this.eventValue) {
      return
    }
    if (!this.dataHashedValue) {
      return
    }

    this.track()
  }

  track() {
    function onIdentifyCompleteCallback(email, isSMSEnabled, phone) {
      if (isSMSEnabled) {
        window.subscribeToKlaviyo({email: email, phone: phone})
      } else {
        window.subscribeToKlaviyo({email: email})
      }
    }

    if (this.eventValue === "IdentifyAndSubscribe") {
      const identityProperties = {
        "$email": this.dataHashedValue.email,
        "$first_name": this.dataHashedValue.first_name,
        "$last_name": this.dataHashedValue.last_name,
        "$birthdate": this.dataHashedValue.birthdate,
        "$phone": this.dataHashedValue.phone
      }

      window.klaviyo.identify(
        identityProperties,
        onIdentifyCompleteCallback(
          this.dataHashedValue.email,
          this.dataHashedValue.is_sms_enabled,
          this.dataHashedValue.phone
        )
      )
    }
  }
}
