import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "form",
    "textarea",
    "styleReasonResponseTitle",
    "defaultReasonResponseTitle",
    "styleReasonResponseBody",
    "defaultReasonDefaultBody"
  ]

  toggleOtherTextareaRequired(event) {
    const textareaTarget = this.textareaTarget

    if (event.target.value === "other") {
      textareaTarget.setAttribute("required", true)
      textareaTarget.focus()
    }
  }

  submitResponse(event) {
    event.preventDefault()

    if (!this.checkFormValidity()) {
      return
    }

    this.formTarget.requestSubmit()
    this.disableForm()

    this.styleReasonResponseBodyTarget.classList.remove("d-none")
    this.defaultReasonDefaultBodyTarget.classList.remove("d-none")
    this.styleReasonResponseTitleTarget.classList.remove("d-none")
    this.defaultReasonResponseTitleTarget.classList.remove("d-none")

    // get the value of the checked radio button
    const cancelReasonRadioButtons = this.formTarget.querySelectorAll("input[type=radio]:checked")
    let cancelReason = ""
    if (cancelReasonRadioButtons.length == 0) {
      return
    } else {
      cancelReason = cancelReasonRadioButtons[0].value
    }

    $("#questionsModal").modal("hide")

    $("#responseModal").modal("show")

    if (cancelReason === "style") {
      this.showStyleReasonResponseModalContents()
    } else {
      this.showDefaultReasonResponseModalContents()
    }
  }

  showStyleReasonResponseModalContents() {
    this.styleReasonResponseBodyTarget.classList.remove("d-none")
    this.styleReasonResponseTitleTarget.classList.remove("d-none")

    this.defaultReasonDefaultBodyTarget.classList.add("d-none")
    this.defaultReasonResponseTitleTarget.classList.add("d-none")
  }

  showDefaultReasonResponseModalContents() {
    this.defaultReasonDefaultBodyTarget.classList.remove("d-none")
    this.defaultReasonResponseTitleTarget.classList.remove("d-none")

    this.styleReasonResponseBodyTarget.classList.add("d-none")
    this.styleReasonResponseTitleTarget.classList.add("d-none")
  }

  disableForm() {
    this.formTarget.querySelectorAll("input").forEach((input) => {
      input.disabled = true
    })

    this.textareaTarget.disabled = true
    this.formTarget.querySelector("input[type=submit").disabled = true
  }

  checkFormValidity() {
    // Check if they have selected at lease one radio button
    if (this.formTarget.querySelectorAll("input[type=radio]:checked").length == 0) {
      return false
    }

    // Check if they have selected the "other" radio button and entered a reason
    const checkedOtherRadio = this.formTarget.querySelector("input[type=radio]:checked").value === "other"
    if (checkedOtherRadio && this.textareaTarget.value === "") {
      return false
    }

    return true
  }
}