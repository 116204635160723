import { Controller } from "@hotwired/stimulus"
import { debounce } from "lodash"
// Just keep here for clarity, we don't need to import it here because it's already imported in app/assets/.
// import { FormValidation } from '@form-validation/umd/bundle/popular'

import "select2"
import { EMAIL_REGEX, FORM_VALIDATION_EMAIL_REGEX, NAME_REGEXP, shiftYears } from "../../utils/common"
import moment from "moment"

export default class extends Controller {
  static targets = [ "direction" ]
  static values = { validateOnConnect: Boolean, default: false, offerType: String }

  /* TODO: This is prone to bug, changing the steps in the backend will break the frontend */
  /* We will try to fix this eventually in a separate TODO */
  static SHIPPING_INFO_STEP = "10"

  connect () {
    this.runConnect()
    this.clearErrorsAfterTyping()
  }

  disconnect () {
    if (window.customerFormValidation) {
      window.customerFormValidation.destroy()
    }
  }

  toggleBillingAddress (event) {
    const active = !$("#billing-same-as").is(":checked")
    $(".billing-addr").toggleClass("active", active)
  }

  toggleShippingAddress (event) {
    const checkboxElement = $(this.element).find("#shipping-same-as-billing")
    const active = checkboxElement.hasClass("active")

    if (active) {
      // clear
      checkboxElement.removeClass("active")
      $("input.zip").val("")
      $("input.city").val("")
      $("input.street").val("")
      $("select.state_id").val("").trigger("change")
    } else {
      // restore
      checkboxElement.addClass("active")
      $("input.zip").val(checkboxElement.data("shipping-address-zip"))
      $("input.city").val(checkboxElement.data("shipping-address-city"))
      $("input.street").val(checkboxElement.data("shipping-address-street"))
      $("select.state_id").val(checkboxElement.data("shipping-address-state-id")).trigger("change")
    }
  }

  uncheckShippingSameAsBilling() {
    // Uncheck shipping-same-as
    const checkboxElement = $(this.element).find("#shipping-same-as-billing")
    if (checkboxElement.length) {
      if ($("input.zip").val() == checkboxElement.data("shipping-address-zip") &&
          $("input.city").val() == checkboxElement.data("shipping-address-city") &&
          $("input.street").val() == checkboxElement.data("shipping-address-street") &&
          $("select.state_id").val() == checkboxElement.data("shipping-address-state-id")) {
        $("#shipping-same-as").prop("checked", true)
        checkboxElement.addClass("active")
      } else {
        $("#shipping-same-as").prop("checked", false)
        checkboxElement.removeClass("active")
      }
    }
  }

  runConnect () {
    const offerType = this.offerTypeValue

    function isPaypal() {
      return $("#payment_method_type").val() === "paypal"
    }

    const validateEmail = debounce((email, resolve, reject) => {
      if (offerType) {
        return resolve({valid: true})
      }

      fetch("/validator/validate_email", {
        method: "POST",
        headers:  {
          "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify({ email })
      }).then(response => response.json()).then(result => {
        resolve({
          message: "Customer with this email already exists",
          meta: result,
          valid: result.valid
        })
      }).catch(error => {
        reject({
          valid: false,
          message: "Failed to validate email",
          meta: {}
        })
      })
    }, 300)

    // https://3.basecamp.com/3611527/buckets/21056952/card_tables/cards/7378844569
    function validateBirthdate(value) {
      const birthdate = new moment("01/" + value, "DD/MM/YYYY", true)
      if (!birthdate.isValid()) {
        return false
      }
      return birthdate.isAfter(shiftYears(150)) && birthdate.isBefore(shiftYears(10))
    }

    const customerForm = $(this.element)
    const addressValidationSkipControl = $("#address-validation-controls")

    // TODO: Why these validations are not called on FE and goes directly to BE for validation?
    const form = this.element
    const pluginsAndFields = {
      plugins: {
        bootstrap: new FormValidation.plugins.Bootstrap(), // eslint-disable-line
      },
      fields: {
        "web_signup[first_name]": {
          validators: {
            notEmpty: {
              message: "Required",
            },
            callback: {
              message: "The input is not a valid name",
              callback: (input) => (!input.value || NAME_REGEXP.test(input.value))
            }
          },
        },
        "web_signup[last_name]": {
          validators: {
            notEmpty: {
              message: "Required",
            },
            callback: {
              message: "The input is not a valid name",
              callback: (input) => (!input.value || NAME_REGEXP.test(input.value))
            }
          },
        },
        "web_signup[email]": {
          validators: {
            notEmpty: {
              message: "Required",
            },
            callback: {
              message: "The input is not a valid email address",
              callback: (input) => (!input.value ||
                (EMAIL_REGEX.test(input.value) && FORM_VALIDATION_EMAIL_REGEX.test(input.value)))
            },
            promise: {
              promise: (input) => {
                return new Promise((resolve, reject) => {
                  if (input.value === "" || input.value === null) {
                    resolve({
                      valid: true
                    })

                    return
                  }

                  validateEmail(input.value, resolve, reject)
                })
              }
            }
          },
        },
        "web_signup[birthdate]": {
          validators: {
            notEmpty: {
              message: "Required"
            },
            regexp: {
              regexp: "^(0[1-9]|10|11|12)/[1-2][0-9]{3}$",
              message: "Invalid format"
            },
            callback: {
              message: "Hmm, your birthday doesn't seem right, please enter an accurate date",
              callback: (input) => (!input.value || validateBirthdate(input.value))
            }
          },
        },
        "web_signup[phone]": {
          validators: {
            notEmpty: {
              message: "Required"
            },
            phone: {
              country: "US",
              message: "Invalid phone number",
            },
            callback: {
              message: "Invalid phone number",
              callback: (input) => (input.value.length == 0 || input.value.replace(/[^0-9]/g, "").length === 10)
            },
          },
        },
        "web_signup[customer_style][top_size]": {
          excluded: false,
          validators: {
            notEmpty: {
              message: "Please choose your Top Size",
            },
          },
        },
        "web_signup[customer_style][bottom_size]": {
          excluded: false,
          validators: {
            notEmpty: {
              message: "Please choose your Bottom Size",
            },
          },
        },
        "web_signup[preferences][cardholder_name]": {
          validators: {
            callback: {
              message: "Required",
              callback: function (input) {
                return isPaypal() || input.value.length > 0
              },
            },
          },
        },
        "web_signup[shipping_address][street]": {
          validators: {
            notEmpty: {
              message: "Required",
            },
          },
        },
        "web_signup[shipping_address][city]": {
          validators: {
            notEmpty: {
              message: "Required",
            },
          },
        },
        "web_signup[shipping_address][state_id]": {
          validators: {
            notEmpty: {
              message: "Required",
            },
          },
        },
        "web_signup[shipping_address][zip]": {
          validators: {
            notEmpty: {
              message: "Required",
            },
          },
        },
        "web_signup[billing_address][street]": {
          validators: {
            message: "Required",
            callback: function (input) {
              return this.isPaypal() || input.value.length > 0
            },
          },
        },
        "web_signup[billing_address][city]": {
          validators: {
            message: "Required",
            callback: function (input) {
              return this.isPaypal() || input.value.length > 0
            },
          },
        },
        "web_signup[billing_address][state_id]": {
          validators: {
            message: "Required",
            callback: function (input) {
              return this.isPaypal() || input.value.length > 0
            },
          },
        },
        "web_signup[billing_address][zip]": {
          validators: {
            message: "Required",
            callback: function (input) {
              return this.isPaypal() || input.value.length > 0
            },
          },
        },
        "web_signupz[customer_style][hated_colors][]": {
          excluded: false,
          validators: {
            callback: {
              message: "Please limit selection to only 6 colors you dislike.",
              callback: function (input) {
                return (
                  $(
                    'input[name="web_signup[customer_style][hated_colors][]"]:checked'
                  ).length < 7
                )
              },
            },
          },
        },
      },
    }

    const that = this
    const formValidation = FormValidation.formValidation(form, pluginsAndFields) // eslint-disable-line

    formValidation.on("core.field.invalid", function (field) {
      that.uncheckShippingSameAsBilling()

      if (field === "web_signup[shipping_address][state_id]") {
        $("span.select2-container").removeClass("is-valid")
        $("span.select2-container").addClass("is-invalid")
        $("div.web_signup_shipping_address_state_id").addClass("form-group-invalid")
        $("div.web_signup_shipping_address_state_id").removeClass("form-group-invalid")
      }
      if (field === "web_signup[billing_address][state_id]") {
        $("span.select2-container").removeClass("is-valid")
        $("span.select2-container").addClass("is-invalid")
        $("div.web_signup_billing_address_state_id").addClass("form-group-invalid")
        $("div.web_signup_billing_address_state_id").removeClass("form-group-invalid")
      }
    })

    formValidation.on("core.field.valid", function (field) {
      that.uncheckShippingSameAsBilling()

      if (field === "web_signup[shipping_address][state_id]") {
        $("span.select2-container").removeClass("is-invalid")
        $("span.select2-container").addClass("is-valid")
        $("div.web_signup_shipping_address_state_id").addClass("form-group-valid")
        $("div.web_signup_shipping_address_state_id").removeClass("form-group-invalid")
      }
      if (field === "web_signup[billing_address][state_id]") {
        $("span.select2-container").removeClass("is-invalid")
        $("span.select2-container").addClass("is-valid")
        $("div.web_signup_billing_address_state_id").addClass("form-group-valid")
        $("div.web_signup_billing_address_state_id").removeClass("form-group-invalid")
      }
    })

    // TODO: is this still relevant?
    // Button must have text content according to accessibility requirements
    // see https://www.w3.org/TR/WCAG20/#text-equiv
    customerForm.find(":submit.fv-hidden-submit").text("Submit")

    // Make the formValidation global so it can be used from elsewhere
    window.customerFormValidation = formValidation

    $(".js-select2").select2({
      placeholder: "State"
    })
    $(".js-select2").on("select2:select", function (e) {
      formValidation.revalidateField("web_signup[shipping_address][state_id]")
      if ($("input[name='web_signup[billing_address][state_id]']").length) {
        formValidation.revalidateField("web_signup[billing_address][state_id]")
      }
      $("span.select2-container").addClass("has_fph")
      $(".select2-state_id-label").removeClass("d-none")
      $(".select2-state_id-label").show()
    })
    $("span.select2-container").addClass("form-control")

    if ($(".js-select2").hasClass("is-invalid")) {
      $("span.select2-container").addClass("is-invalid")
    }
    if ($(".js-select2").hasClass("is-valid")) {
      $("span.select2-container").addClass("is-valid")
    }

    $(document).on("focus", ".select2.select2-container", function (e) {
      // if this element"s sibling is a select with id of customer_shipping_address_state_id continue
      const enabledSelect = $(this).siblings("select:enabled").attr("id")
      const isStateSelectElement =  enabledSelect === "web_signup_shipping_address_state_id"
      if (!isStateSelectElement) {
        return
      }

      // don"t re-open on closing focus event
      const isOriginalEvent = e.originalEvent

      // multi-select will pass focus to input
      const isSingleSelect = $(this).find(".select2-selection--single").length > 0

      if (isOriginalEvent && isSingleSelect) {
        $(this).siblings("select:enabled").select2("open")
      }
    })

    // Autocompletion for birthdate field
    function birthdateAutoComplete(evt) {
      const v = this.value
      if (v.match(/^\d{2}$/) != null) {
        if (evt.keyCode != 8 && evt.keyCode != 46) {
          this.value = v + "/"
        }
      }
    }

    function phoneNumberAutoComplete(evt) {
      // Prevent entry of more numbers once the phone number is complete.
      const phoneNumber = this.value.replace(/\D/g, "")
      if (phoneNumber.length > 10) {
        evt.preventDefault()
      }

      this.value = formatPhoneNumber(phoneNumber)
    }

    function formatPhoneNumber(phoneNumber) {
      const characterPosition = { 0: "(", 3: ") ", 6: "-" }

      let formattedPhone = ""
      for (let i = 0; i < phoneNumber.length; i++) {
        formattedPhone += (characterPosition[i] || "") + phoneNumber[i]
      }

      return formattedPhone
    }

    if ($("input.phone").length) {
      $("input.phone").val(formatPhoneNumber($("input.phone").val().replace(/\D/, "")))
    }

    // TODO: move into Stimulus controllers and attach to specific fields
    $("input.birthdate").on("keyup",birthdateAutoComplete)
    $("input.phone").on("keyup", phoneNumberAutoComplete)

    $("input.cardholder_name").on("keyup", function () {
      window.customerFormValidation.revalidateField("web_signup[preferences][cardholder_name]")
    })
    if (this.validateOnConnectValue) {
      setTimeout(() => this.revalidateFields(), 300)
    }

    // Keep progress bar sticky under the site-navbar
    const styleProgress = $("#style-progress-wrapper")
    styleProgress.css("top", $("nav.site-navbar.sticky-top").css("height"))
  }

  next(event) {
    event.preventDefault()

    const form = this.element
    const isShippingInfoStep = form.step.value === this.constructor.SHIPPING_INFO_STEP
    if (isShippingInfoStep) {
      window.customerFormValidation.validate().then(status => {
        if (status == "Valid") {
          form.requestSubmit()
        }
      })
    } else {
      form.requestSubmit()
    }
  }

  prev(event) {
    event.preventDefault()
    $(this.directionTarget).val("prev")
    this.element.requestSubmit()
  }

  revalidateFields() {
    if ($("#web_signup_shipping_address_state_id").length) {
      window.customerFormValidation.validate()
    }
  }

  // flow: baseline ~ #register_btn
  submit(event) {
    window.customerFormValidation.validate().then(status => {
      if (status == "Valid") {
        // Show loader
        const loader = $("#loader")
        loader.show()
        loader[0].scrollIntoView({ behavior: "smooth", block: "center" })
      }
    })
  }

  clearErrorsAfterTyping() {
    // clear all errors when a field is changed
    const formElementSelectors = [
      "web_signup[first_name]",
      "web_signup[last_name]",
      "web_signup[birthdate]",
      "web_signup[email]",
      "web_signup[phone]",
      "web_signup[shipping_address][street]",
      "web_signup[shipping_address][city]",
      "web_signup[shipping_address][state_id]",
      "web_signup[shipping_address][zip]"
    ]

    formElementSelectors.forEach(selector => {
      const inputSelector = `[name='${selector}']`
      const el = $(inputSelector)

      let triggerEvent = "input"
      let elementWithErrors = el

      if (el.is("select")) {
        triggerEvent = "change"
        elementWithErrors = el.siblings(".select2")
        // Temporary fix select to match input fields css until duplicate code merge
        if (el.val() != "") {
          elementWithErrors.addClass("has_fph")
        }
      }

      el.on(triggerEvent, function () {
        if (elementWithErrors.hasClass("is-invalid")) {
          elementWithErrors.removeClass("is-invalid")
          elementWithErrors.removeClass("is-valid")

          const errorLabelSelector = `[data-field='${selector}']`
          $(errorLabelSelector).remove()
        }
      })
    })
  }

  devpop(event) {
    event.preventDefault()
    console.log("Populated form with dev example data")
    $("input.first_name").val("Mary")
    $("input.last_name").val("West")
    $("input.email").val("mary.west@example.com")
    $("input.birthdate").val("11/1975")
    $("input.phone").val("1231231234")
    $("input.zip").val("78704")
    $("input.city").val("Austin")
    $("input.street").val("8233 Industry Way")
    $("select.state_id").select2("trigger", "select", {
      data: {id: "48"} // Texas
    })
  }
}
