import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  pushState
  static targets = ["signupTurboFrame", "signupForm"]

  connect() {
    this.pushState = true
  }

  signupFormTargetDisconnected() {
    $(window).off("popstate")
  }

  signupFormTargetConnected(target) {
    const that = this
    const form = $(this.signupFormTarget)
    const step = form.attr("data-step")
    const stepUrl = form.attr("data-step-url")
    const signupTurboFrameSrcUrl = new URL(stepUrl)
    const url = new URL(location)

    if (this.pushState) {
      // update current browser location
      // and push state and url
      url.searchParams.set("step", step)
      history.pushState({step: step}, "", url)
    } else {
      this.pushState = true
    }

    // Catch the event that is fired when the customer clicks on the back/forward browser buttons
    $(window).on("popstate", function() {
      event.preventDefault()
      event.stopPropagation()

      if (history.state && history.state.step) {
        // Do nothing if a modal is open
        if ($(".modal.show").length) {
          return
        }

        signupTurboFrameSrcUrl.searchParams.set("step", history.state.step)

        // Don't push the popped state when the frame reconnects, otherwise we can't go back very far :)
        that.pushState = false

        // Load popped state by changing turbo frame src on the fly!
        // $(this.signupTurboFrameTarget).attr("src", signupTurboFrameSrcUrl) <- TODO: Why is this not working?
        $("#signup_frame").attr("src", signupTurboFrameSrcUrl)
      } else {
        location.reload()
      }
    })
  }
}

