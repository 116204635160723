import { Controller } from "@hotwired/stimulus"

/** Feature description
 * 
 * Each question that has limited options in the quiz can be single select / multiple checkboxes
 * If they have an "other" option, then a textarea should appear if that option is selected
 * For the select, the text area gets the name of the select input.
 * For the checkboxes, the text area gets the name of the checkbox input.
 */ 
export default class extends Controller {
  static targets = ["textarea"]

  toggleOtherTextareaFromSelect() {
    const moreSelectInput = this.element.querySelector("select")
    const textareaTarget = this.textareaTarget
    let originalFieldName = moreSelectInput.name

    const isMoreTextAreaHidden = textareaTarget.classList.contains("d-none")
    if (isMoreTextAreaHidden) {
      originalFieldName = moreSelectInput.name
    } else {
      originalFieldName = textareaTarget.name
    }

    if (moreSelectInput.value === "other") {
      textareaTarget.setAttribute("name", originalFieldName)
      textareaTarget.classList.remove("d-none")
      textareaTarget.removeAttribute("disabled")
      textareaTarget.focus()

      moreSelectInput.removeAttribute("name")
    } else {
      textareaTarget.setAttribute("disabled", true)
      textareaTarget.classList.add("d-none")
  
      moreSelectInput.setAttribute("name", originalFieldName)
    }
  }

  toggleOtherTextareaFromMultiCheckbox() {
    const moreCheckboxInput = this.element.querySelector("input[type=checkbox][value=other]")
    if(!moreCheckboxInput) {
      return // it is a regular checkbox, ignore
    }

    const textareaTarget = this.textareaTarget
    let originalFieldName = moreCheckboxInput.name

    const isMoreTextAreaHidden = textareaTarget.classList.contains("d-none")
    if (isMoreTextAreaHidden) {
      originalFieldName = moreCheckboxInput.name
    } else {
      originalFieldName = textareaTarget.name
    }

    if (moreCheckboxInput.checked) {
      textareaTarget.setAttribute("name", originalFieldName)
      textareaTarget.classList.remove("d-none")
      textareaTarget.removeAttribute("disabled")
      textareaTarget.focus()

      moreCheckboxInput.removeAttribute("name")
    } else {
      textareaTarget.setAttribute("disabled", true)
      textareaTarget.classList.add("d-none")
  
      moreCheckboxInput.setAttribute("name", originalFieldName)
    }
  }
}
