import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "planCard"
  ]

  connect () {
    this.planCardTargets.forEach ((planCardTarget) => {
      if (planCardTarget.classList.contains("selected")) {
        const plan = $(planCardTarget).data("plan")
        if (plan) {
          setTimeout(() => {
            this.notify(plan)
          }, 300)
        }
      }
    })
  }

  select (event) {
    this.planCardTargets.forEach((planCardTarget) => {
      planCardTarget.classList.remove("selected")

      if (planCardTarget.dataset.plan === event.currentTarget.dataset.plan) {
        planCardTarget.classList.add("selected")

        const plan = $(planCardTarget).data("plan")
        this.notify(plan)
      }
    })
  }

  // PRIVATE

  /*
   * Notify to any element that listens to the "plan-updated" event
   * @param {Object} plan
   */
  notify (plan) {
    const event = new CustomEvent("plan:updated", {detail: {plan: plan}})
    window.dispatchEvent(event)
  }
}
