import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie"

export default class extends Controller {
  agree() {
    Cookies.set("customer_allowed_cookies", true)
    window.ttq.enableCookie()
    window.pintrk("setconsent", true)
    this.hideModal()
  }

  disagree() {
    ["rakuten_info", "nw_profile", "nw_gc_popup", "signup", "_ttp"].forEach(function(cookieName) {
      Cookies.remove(cookieName)
    })
    Cookies.set("customer_allowed_cookies", false)
    window.ttq.disableCookie()
    window.pintrk("setconsent", false)
    this.hideModal()
  }

  hideModal() {
    $(this.element).modal("toggle")
  }
}
