import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie"

export default class extends Controller {
  connect() {
    const giftCardsPopupAlreadyShownCookie = Boolean(Cookies.get("nw_gc_popup"))
    if (!giftCardsPopupAlreadyShownCookie) {
      $(this.element).modal("show")
      Cookies.set("nw_gc_popup", "1", { expires: 4 })
    }

    $(this.element).on("hidden.bs.modal", function () {
      Cookies.set("nw_gc_popup", "1", { expires: 4 })
    })
  }
}
