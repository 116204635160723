/* global sendGA4Event */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    event: String,
    optedin: Boolean,
    paymentMethod: String,
    transactionId: String,
    experimentName: String,
    variantName: String
  }

  connect() {
    if (!this.eventValue) {
      console.error("GaController missing eventValue!")
      return
    }

    this.sendEvent(this.eventValue)
  }

  sendEvent(eventName) {
    let eventParams
    let stepNumber, stepName, previousStepNumber, previousStepName

    switch (eventName) {
    case "ab_experiment":
      eventParams = {
        event: eventName,
        experiment_name: this.experimentNameValue,
        variant_name: this.variantNameValue
      }
      sendGA4Event(eventParams)
      break
    case "lead":
      eventParams = { event: eventName, optedin: this.optedinValue }
      sendGA4Event(eventParams)
      break
    case "signup":
      eventParams = {
        event: eventName,
        payment_method: this.paymentMethodValue,
        transaction_id: this.transactionIdValue
      }
      sendGA4Event(eventParams)
      break
    case "quiz_step":
      stepNumber = $("#customer_form").attr("data-step")
      stepName = $("#customer_form").attr("data-step-name")
      previousStepNumber = $("#customer_form").attr("data-prev-step")
      previousStepName = $("#customer_form").attr("data-prev-step-name")

      eventParams = {
        event: eventName,
        step: stepNumber,
        step_name: stepName,
        previous_step: previousStepNumber,
        previous_step_name: previousStepName
      }
      sendGA4Event(eventParams)
      sendGA4Event({ ...eventParams, event: stepName })
      break
    default:
    }
  }
}
