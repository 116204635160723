import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  PLAN_MAPS = {
    standard: "Standard Membership",
    premium: "Fall-For-You Custom Membership"
  }

  static targets = [
    "total",
    "price",
    "lineItem",
    "planIdInput",
    "submitButton"
  ]

  static values = {
    "defaultPlan": Object
  }

  connect () {
    this.updatePlan(this.defaultPlanValue)
  }

  // Private

  /**
   * This method will update the Order Summary UI based on the incoming event
   * @param {CustomEvent} event
   */
  update (event) {
    const { plan, submittable } = event.detail

    if (plan) {
      this.lineItemTarget.innerHTML = this.PLAN_MAPS[plan.name]
      this.priceTarget.innerHTML = this.formatAmount(plan.upfront_amount_on_signup)
      this.totalTarget.innerHTML = this.formatAmount(plan.upfront_amount_on_signup)
      this.planIdInputTarget.value = plan.id
    }

    if (submittable === true) {
      this.enableSubmitButton()
    } else if (submittable === false) {
      this.disableSubmitButton()
    }
  }

  updatePlan (plan) {
    if (plan) {
      this.lineItemTarget.innerHTML = this.PLAN_MAPS[plan.name]
      this.priceTarget.innerHTML = this.formatAmount(plan.upfront_amount_on_signup)
      this.totalTarget.innerHTML = this.formatAmount(plan.upfront_amount_on_signup)
      this.planIdInputTarget.value = plan.id
    }
  }

  enableSubmitButton = () => ( this.submitButtonTarget.disabled = false )

  disableSubmitButton = () => ( this.submitButtonTarget.disabled = true )

  formatAmount = (amount) => (`$${amount}0`)
}
