/* global ttq */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    event: String,
    customerEmail: String,
    customerPhone: String,
  }

  connect() {
    if (!this.eventValue) {
      console.error("TiktokController missing eventName!")
      return
    }

    if (window.nwApp.isProduction || window.nwApp.isStaging) {
      this.sendEvent(this.eventValue)
    } else {
      console.log(`tiktok pixel ttp triggered for ${this.eventValue}`)
    }
  }

  sendEvent(eventName) {
    switch (eventName) {
    case "ViewContent":
    case "ClickButton":
      ttq.track(eventName)
      break
    case "Subscribe":
      ttq.identify({
        sha256_email: window.sha256(this.customerEmailValue),
        sha256_phone_number: window.sha256(this.customerPhoneValue),
      })
      ttq.track(eventName, {_ttp: window.Cookies.get("_ttp")})
      break
    default:
      console.error(`TiktokController unknown eventName: ${eventName}`)
    }
  }
}
