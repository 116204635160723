import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "readMoreButton"]
  static values = {
    moreText: String,
    lessText: String,
    maxTextLength: Number
  }

  connect () {
    this.open = false
    const textLength = this.contentTarget.innerText.length

    if (textLength < this.maxTextLengthValue) {
      this.readMoreButtonTarget.style.display = "none"
    }
  }

  toggle (event) {
    this.open === false ? this.show(event) : this.hide(event)
  }

  show (event) {
    this.open = true

    const target = event.target
    target.innerHTML = this.lessTextValue
    this.contentTarget.style.setProperty("--read-more-line-clamp", "'unset'")
  }

  hide (event) {
    this.open = false

    const target = event.target
    target.innerHTML = this.moreTextValue
    this.contentTarget.style.removeProperty("--read-more-line-clamp")
  }
}
