import { Controller } from "@hotwired/stimulus"
import {executeOnReadyHandler} from "@nadinewest/common"

// Run legacy code after streaming a frame
// The OnPageReadyHandlers from the common code have to run again when the DOM gets modified by turbo.
// See https://github.com/NadineWest/web-common/blob/master/javascript/src/page_ready_handlers.js
export default class extends Controller {
  // A controller#connect will run on every frame render,
  // so it's a good fit to run legacy js that nees to be re-inited due to DOM replace.
  connect() {
    // console.log("ready_controller connected executeOnReadyHandler")
    executeOnReadyHandler()
  }

  disconnect() {
    // Destroy the DataTable
    if (window.currentDataTable) {
      window.currentDataTable.destroy()
    }
    if (window.dataTables) {
      Object.keys(window.dataTables).forEach(function (key) {
        window.dataTables[key].destroy()
      })
    }
  }
}
