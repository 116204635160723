/* global google */

import { Controller } from "@hotwired/stimulus"

const SHORT_NAME_ADDRESS_COMPONENT_TYPES = ["street_number", "administrative_area_level_1", "postal_code"]

export default class extends Controller {
  static targets = ["location", "city", "stateId", "zip", "country"]

  static values = {
    states: Object,
  }

  connect() {
    // check for flag if Google Maps has loaded early, before page is fully ready to handle it,
    // otherwise wait for the event to fire
    if (window.googleMapsLoaded) {
      this.initAutocomplete()
    } else {
      document.addEventListener("google-maps-loaded", () => this.initAutocomplete(), { once: true })
    }
  }

  initAutocomplete() {
    const { Autocomplete } = google.maps.places
    const autocomplete = new Autocomplete(this.locationTarget, {
      fields: ["address_components", "name"],
      types: ["address"],
      componentRestrictions: { country: "US" }
    })

    autocomplete.addListener("place_changed", () => {
      this.place = autocomplete.getPlace()
      this.fillInAddress()
      this.dispatchInputEvents()
    })
  }

  fillInAddress() {
    this.cityTarget.value = this.getComponentText("locality")
    this.locationTarget.value = this.getComponentText("location")
    $(this.stateIdTarget).select2("trigger", "select", {
      data: {id: this.getComponentText("state")}
    })
    this.zipTarget.value = this.getComponentText("postal_code")
  }

  // dispatch input events to trigger validation
  dispatchInputEvents() {
    const inputEvent = new Event("input", {
      bubbles: true,
      cancelable: true
    })

    this.cityTarget.dispatchEvent(inputEvent)
    this.zipTarget.dispatchEvent(inputEvent)
  }

  getComponentText(componentType) {
    let value
    switch (componentType) {
    case "location":
      value = [this.getComponentName("street_number"), this.getComponentName("route")].filter(Boolean).join(" ")
      break
    case "state":
      value = this.statesValue[this.getComponentName("administrative_area_level_1")]
      break
    default:
      value = this.getComponentName(componentType)
    }

    return value || ""
  }

  getComponentName(componentType) {
    for (const component of this.place.address_components || []) {
      if (component.types[0] === componentType) {
        return SHORT_NAME_ADDRESS_COMPONENT_TYPES.includes(componentType) ?
          component.short_name :
          component.long_name
      }
    }
  }
}
