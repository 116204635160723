import { Controller } from "@hotwired/stimulus"

// Buttons instead of select
export default class extends Controller {
  static targets = [ "hiddenSelect" ]

  choose (event) {
    // clear active button state
    $(this.element).find(".thumbnail-btn.active").removeClass("active").attr("aria-checked", false)

    // set clicked button as active
    const btn = $(event.currentTarget)
    btn.addClass("active")
    btn.attr("aria-checked", true)

    // update the hidden select control. `split` is quick&dirty way to make it work for body-type step too
    const text = btn.text().trim().split("\n")[0]
    const selectElement = $(this.hiddenSelectTarget)
    selectElement.find("option").filter(function(index) {
      const optionText = $(this).text().trim()
      if (optionText === text) {
        selectElement.prop("selectedIndex", index)
      }
      return false
    })
  }
}



