import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

// Notification
export default class extends Controller {
  static values = {
    scrollEnabled: { type: Boolean, default: true },
    reloadPage: { type: Boolean, default: false },
    blockPage: { type: Boolean, default: false }
  }
  static targets = [ "close", "confirmation" ]

  connect() {
    const notificationElement = $(this.element)

    // Scrolling with focusable controller and scrollIntoView did not work well for notifications
    // So using scrollTop instead:
    // Scroll alerts (warnings)
    if (this.scrollEnabledValue) {
      if ($(this.closeTarget).parent().hasClass("alert-warning")) {
        $("html, body").animate({
          scrollTop: notificationElement.offset().top - notificationElement.height() - 150
        }, 1000)
      }
    }

    if (this.blockPageValue) {
      this.showBackdrop()
      return
    }

    if (this.reloadPageValue) {
      // Scroll both alerts and success notifications when reloading!
      if ($(this.closeTarget).parent().hasClass("alert-success")) {
        $("html, body").animate({
          scrollTop: notificationElement.offset().top - notificationElement.height() - 150
        }, 1000)
      }
      // Force a window reload after showing the alert!
      setTimeout(() => {
        Turbo.visit(window.location.toString(), {action: "replace"})
      }, 1500)
    } else {
      // Dismiss automatically after 10s for success notifications
      if ($(this.closeTarget).parent().hasClass("alert-success")) {
        setTimeout(() => this.dismiss(), 10000)
      }
    }
  }

  disconnect() {
    if (this.blockPageValue) {
      this.hideBackdrop()
    }
  }

  dismiss(event) {
    if (event && this.hasConfirmationTarget && event.target === this.confirmationTarget) {
      event.stopPropagation()
    } else {
      this.closeTarget.click()
    }

    if (this.blockPageValue) {
      this.hideBackdrop()
    }
  }

  confirm(event) {
    // Is there a nicer way to hide my self upon submit?
    // Hiding notification after confirmation submit form action
    setTimeout(() => this.dismiss(), 100)
  }

  showBackdrop() {
    const backdropElement = $("#backdrop")
    if (backdropElement.hasClass("visible")) {
      return
    }

    backdropElement.addClass("visible")
    this.disableScanInput()
  }

  hideBackdrop() {
    $("#backdrop").removeClass("visible")
  }
}
