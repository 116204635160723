import { Controller } from "@hotwired/stimulus"
import { formatAmount } from "../../utils/formatters"

export default class extends Controller {
  static targets = [
    "giftCard",
    "quantity",
    "total"
  ]

  connect () {
    this.calculateTotal()
  }

  calculateTotal () {
    const price = $(this.giftCardTarget).find(":selected").data("price")
    const quantity = $(this.quantityTarget).val()
    const total = (price * quantity).toFixed(2)

    $(this.totalTarget).val(formatAmount(total))
  }
}
