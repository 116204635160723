import { Controller } from "@hotwired/stimulus"
import client from "braintree-web/client"
import paypalCheckout from "braintree-web/paypal-checkout"
import applePay from "braintree-web/apple-pay"
import hostedFields from "braintree-web/hosted-fields"
import debounce from "lodash/debounce"
import { EMAIL_REGEX, FORM_VALIDATION_EMAIL_REGEX } from "../../utils/common"

const PAYMENT_TYPES = {
  paypal: "paypal",
  cc: "credit_card",
  apple_pay: "apple_pay"
}

// This controller is used for the payment form on the /offer page
export default class extends Controller {
  isSubmitting = false
  paymentValid = false
  formValidation
  selectedPlan

  static targets = [
    "creditCardInputs" ,
    "emailInput",
    // NOTE: Payment Related Targets
    "applePayButton",
    "applePayStatus",
    "paypalButton",
    "paypalStatus",
    "creditCardButton",
    "creditCardStatus",
    "paymentInfo",
    "paymentTypeInput",
    "paymentNonceInput"
  ]

  static values = {
    "revalidate": Boolean,
    "defaultPlan": Object,
    "billingAgreement": String
  }

  initialize () {
    this.validate = debounce(this.validate, 200).bind(this)
    this.notify = debounce(this.notify, 200).bind(this)
  }

  connect () {
    this.initializePaymentMethods()
    this.initializeFormValidations()
    this.initializeBillingAddressStateIdSelect2()

    this.selectedPlan = this.defaultPlanValue

    if (this.revalidateValue) {
      this.validate()
    }
  }

  disconnect () {
    this.formValidation.destroy()
  }

  selectCreditCard () {
    this.selectPaymentType(PAYMENT_TYPES.cc)
    this.resetPaymentInfo()
    this.setPaymentAsInvalid()
  }

  updatePlan (event) {
    const { plan } = event.detail
    this.selectedPlan = plan
  }

  submit (event) {
    this.isSubmitting = true

    // Show loader after submit!
    const loader = $("#loader")
    loader.show()
    loader[0].scrollIntoView({ behavior: "smooth", block: "center"})
  }

  // Private

  validate () {
    this.notify({submittable: this.allFieldsValid()})
  }

  allFieldsValid () {
    return this.paymentValid &&
      this.emailInputTarget.classList.contains("is-valid") &&
      (!this.isCreditCard() ||
        this.creditCardInputsTargets.every((input) => (input.classList.contains("is-valid"))))
  }

  /*
   * Notify to any element that listens to the "plan-updated" event
   */
  notify () {
    if (this.isSubmitting) {
      return
    }

    const event = new CustomEvent("plan-payment-form:updated", {
      detail: { submittable: this.allFieldsValid() }
    })
    window.dispatchEvent(event)
  }

  updatePayPalStatus (status) {
    $(this.paypalStatusTarget).find(".status").html(status)
  }

  updateApplePayStatus (status) {
    $(this.applePayStatusTarget).find(".status").html(status)
  }

  selectPaymentType (type) {
    switch (type) {
    case PAYMENT_TYPES.paypal:
      $(this.paypalButtonTarget).hide()
      this.updatePayPalStatus("connecting")
      $(this.paypalStatusTarget).show()

      $(this.creditCardStatusTarget).hide()
      $(this.creditCardButtonTarget).show()
      $(this.paymentInfoTarget).hide()

      $(this.applePayButtonTarget).show()
      $(this.applePayStatusTarget).hide()

      break
    case PAYMENT_TYPES.apple_pay:
      $(this.applePayButtonTarget).hide()
      this.updateApplePayStatus("connecting")
      $(this.applePayStatusTarget).show()

      $(this.creditCardStatusTarget).hide()
      $(this.paymentInfoTarget).hide()
      $(this.creditCardButtonTarget).show()

      $(this.paypalButtonTarget).show()
      $(this.paypalStatusTarget).hide()

      break
    default:
      $(this.paypalStatusTarget).hide()
      $(this.paypalButtonTarget).show()

      $(this.applePayButtonTarget).show()
      $(this.applePayStatusTarget).hide()

      $(this.creditCardButtonTarget).hide()
      $(this.creditCardStatusTarget).show()
      $(this.paymentInfoTarget).show()
    }
  }

  resetPaymentInfo () {
    $(this.paymentTypeInputTarget).val(null)
    $(this.paymentNonceInputTarget).val(null)
  }

  setPaymentInfo (type, nonce) {
    $(this.paymentTypeInputTarget).val(type)
    $(this.paymentNonceInputTarget).val(nonce)

    if (type === PAYMENT_TYPES.paypal) {
      this.updatePayPalStatus("connected")
    } else if (type === PAYMENT_TYPES.apple_pay) {
      this.updateApplePayStatus("connected")
    }

    this.paymentValid = true
    // now revalidate only email, to show email as missing if user connected account without adding email first
    this.formValidation.revalidateField("web_signup[email]")
  }

  setPaymentAsInvalid () {
    this.paymentValid = false
    this.validate()
  }

  isCreditCard = () => ( [PAYMENT_TYPES.cc, ""].includes($(this.paymentTypeInputTarget).val()) )

  validateEmail = debounce((email, resolve, reject) => {
    fetch("/validator/validate_email", {
      method: "POST",
      headers:  {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify({ email })
    }).then(response => response.json()).then(result => {
      resolve({
        message: "Customer with this email already exists",
        meta: result,
        valid: result.valid
      })
    }).catch(error => {
      reject({
        valid: false,
        message: "Failed to validate email",
        meta: {}
      })
    })
  }, 300)

  // Form Validations
  initializeFormValidations () {
    const form = this.element
    const isCreditCard = this.isCreditCard.bind(this)

    const emailSelector = "web_signup[email]"
    const cardHolderSelector = "web_signup[preferences][cardholder_name]"
    const billingStreetSelector = "web_signup[billing_address][street]"
    const billingCitySelector = "web_signup[billing_address][city]"
    const billingStateIdSelector = "web_signup[billing_address][state_id]"
    const billingZipSelector = "web_signup[billing_address][zip]"

    const pluginsAndFields = {
      plugins: {
        bootstrap: new FormValidation.plugins.Bootstrap(), // eslint-disable-line
        trigger: new FormValidation.plugins.Trigger({ // eslint-disable-line
          event: {
            [emailSelector]: "blur",
            [cardHolderSelector]: "blur keyup",
            [billingStreetSelector]: "blur keyup",
            [billingCitySelector]: "blur keyup",
            [billingStateIdSelector]: "blur keyup",
            [billingZipSelector]: "blur keyup",
          }
        }),
      },
      fields: {
        [emailSelector]: {
          validators: {
            notEmpty: {
              message: "Email is required and cannot be empty",
            },
            callback: {
              message: "The input is not a valid email address",
              callback: (input) => (!input.value ||
                (EMAIL_REGEX.test(input.value) && FORM_VALIDATION_EMAIL_REGEX.test(input.value)))
            },
            promise: {
              promise: (input) => {
                return new Promise((resolve, reject) => {
                  if (input.value === "" || input.value === null) {
                    resolve({
                      valid: true
                    })

                    return
                  }

                  this.validateEmail(input.value, resolve, reject)
                })
              }
            }
          },
        },
        [cardHolderSelector]: {
          validators: {
            callback: {
              message: "Name on card is required and cannot be empty",
              callback: (input) => ( !isCreditCard() || input.value.replace(/\s/g,"").length > 0 )
            },
          },
        },
        [billingStreetSelector]: {
          validators: {
            callback: {
              message: "Street is required and cannot be empty",
              callback: (input) => ( !isCreditCard() || input.value.replace(/\s/g,"").length > 0 )
            },
          },
        },
        [billingCitySelector]: {
          validators: {
            callback: {
              message: "City is required and cannot be empty",
              callback: (input) => ( !isCreditCard() || input.value.replace(/\s/g,"").length > 0 )
            },
          },
        },
        [billingStateIdSelector]: {
          validators: {
            callback: {
              message: "State is required and cannot be empty",
              callback: (input) => ( !isCreditCard() || input.value.replace(/\s/g,"").length > 0 )
            },
          },
        },
        [billingZipSelector]: {
          validators: {
            callback: {
              message: "Zip is required and cannot be empty",
              callback: (input) => ( !isCreditCard() || input.value.replace(/\s/g,"").length > 0 )
            }
          },
        },
      },
    }

    this.formValidation = FormValidation.formValidation(form, pluginsAndFields) // eslint-disable-line

    this.formValidation.on("core.validator.validated", (_) => {
      this.validate()
    })
  }

  // Initialize Select2 for billing address state_id field
  initializeBillingAddressStateIdSelect2 () {
    $(".js-select2").select2({
      placeholder: "State"
    })

    $("span.select2-container").addClass("form-control")

    $(".js-select2").on("select2:select", function (e) {
      this.formValidation.revalidateField("web_signup[billing_address][state_id]")
    }.bind(this))

    this.formValidation.on("core.validator.validated", (event) => {
      if (event.field === "web_signup[billing_address][state_id]") {
        const containerElement = $(event.element).next("span.select2-container")

        if (event.result.valid) {
          if (containerElement.hasClass("is-invalid")) {
            containerElement.removeClass("is-invalid")
          }

          if (!containerElement.hasClass("is-valid")) {
            containerElement.addClass("is-valid")
          }
        } else {
          if (containerElement.hasClass("is-valid")) {
            $(event.element).next("span.select2-container").removeClass("is-valid")
          }

          if (!containerElement.hasClass("is-invalid")) {
            $(event.element).next("span.select2-container").addClass("is-invalid")
          }
        }
      }
    })
  }

  // PAYMENT RELATED
  // Ideally we would want to keep this, Business flow agnostic
  // It should not have any knowledge of the flow, ex. after customer successfully added a payment method
  // it should not care if it should enable a button or not.
  // All it does is just updating a flag paymentValid.
  // The rest of the app should just read value.
  // TODO: Extract this out to something reusable.
  initializePaymentMethods () {
    try {
      if (window.ApplePaySession &&
        window.ApplePaySession.supportsVersion(3) &&
        window.ApplePaySession.canMakePayments()) {
        $("#apple-pay-button-main-container").show()
      }
    } catch (e) {
      console.log(e)
    }

    const createClientCallbacks = (clientErr, clientInstance) => {
      if (clientErr) {
        console.error(clientErr)
        alert("Failed to initialize Braintree Client")
        return
      }

      if ($("#cc-number").length) {
        this.creditCardCallback(clientInstance)
      }
      if ($("#paypal-button").length) {
        this.paypalCallback(clientInstance)
      }

      if ($("#apple-pay-button").length) {
        this.applePayCallback(clientInstance)
      }
    }

    // Start all
    $.get(
      "/api/braintree_token",
      {},
      function(token, status, xhr) {
        client.create({authorization: token}, createClientCallbacks.bind(this))
      },
      "text"
    )
  }

  /*
   * Credit Card
   */
  creditCardCallback (clientInstance) {
    const options = {
      client: clientInstance,
      styles: {
        ".invalid": {"color": "#a94442"},
        ".valid": {"color": "green"},
        "input": {
          "font-size": "16px"
        },
        ":focus": {
          "color": "black"
        },
        "::-webkit-input-placeholder": {
          "color": "#7d7d7d"
        },
        ":-moz-placeholder": {
          "color": "#7d7d7d"
        },
        "::-moz-placeholder": {
          "color": "#7d7d7d"
        },
        ":-ms-input-placeholder": {
          "color": "#7d7d7d"
        }
      },
      fields: {
        number: {selector: "#cc-number", placeholder: "Credit Card Number"},
        cvv: {selector: "#cc-cvv", placeholder: "CVV"},
        expirationDate: {selector: "#cc-exp", placeholder: "MM/YY"}
      }
    }

    hostedFields.create(options, this.initializeHostedFieldsCallback.bind(this))
  }

  initializeHostedFieldsCallback (hostedFieldsErr, hostedFieldsInstance) {
    if (hostedFieldsErr) {
      return
    }

    const isCreditCard = this.isCreditCard.bind(this)

    function doNotValidateCC() {
      return !isCreditCard()
    }

    // returns true if all hosted fields are valid
    function ccAllValid() {
      if (doNotValidateCC()) {
        return true
      }

      const state = hostedFieldsInstance.getState()

      let allValid = true

      for (const key in state.fields) {
        const field = state.fields[key]
        const isValid = field.isValid
        const fieldGroup = $(field.container).parents(".form-group")
        if (!isValid) {
          allValid = false
        }

        if (!field.isPotentiallyValid) {
          ccUpdateValidityStyling(fieldGroup, key, isValid)
        }
      }

      return allValid
    }

    // Update styling for hosted fields
    function ccUpdateValidityStyling(fieldGroup, fieldName, isValid) {
      const numberField = $("#cc-number")
      const expField = $("#cc-exp")
      const cvvField = $("#cc-cvv")

      if (isValid) {
        if (fieldName === "number") {
          numberField.next(".fv-help-block").text("")
          numberField.addClass("is-valid")
          numberField.removeClass("is-invalid")
        } else if (fieldName === "expirationDate") {
          expField.next(".fv-help-block").text("")
          expField.addClass("is-valid")
          expField.removeClass("is-invalid")
        } else if (fieldName === "cvv") {
          cvvField.next(".fv-help-block").text("")
          cvvField.addClass("is-valid")
          cvvField.removeClass("is-invalid")
        }
        fieldGroup.addClass("fv-has-success")
        fieldGroup.removeClass("fv-has-error")
      } else {
        // Potentially valid we process like errors
        fieldGroup.addClass("fv-has-error")
        fieldGroup.removeClass("fv-has-success")
        if (fieldName === "number") {
          numberField.next(".fv-help-block").text("Credit Card Number is not valid")
          numberField.addClass("is-invalid")
          numberField.removeClass("is-valid")
        } else if (fieldName === "expirationDate") {
          expField.next(".fv-help-block").text("Expiration date is not valid")
          expField.addClass("is-invalid")
          expField.removeClass("is-valid")
        } else if (fieldName === "cvv") {
          cvvField.next(".fv-help-block").text("CVV is not valid")
          cvvField.addClass("is-invalid")
          cvvField.removeClass("is-valid")
        }
      }
    }

    function writeNonce() {
      hostedFieldsInstance.tokenize(function (tokenizeErr, payload) {
        if (tokenizeErr) {
          this.setPaymentAsInvalid()
        } else {
          this.setPaymentInfo(PAYMENT_TYPES.cc, payload.nonce)
        }
      }.bind(this))
    }

    const tokenize = writeNonce.bind(this)

    hostedFieldsInstance.on("validityChange", function (event) {
      if (doNotValidateCC()) {
        return
      }

      const fieldName = event.emittedBy
      const field = event.fields[fieldName]
      const isValid = field.isValid
      const fieldGroup = $(field.container).parents(".form-group")
      if (ccAllValid()) {
        tokenize()
      } else {
        this.setPaymentAsInvalid()
      }

      ccUpdateValidityStyling(fieldGroup, fieldName, isValid)
    }.bind(this))

    // Hiding Braintree iframes to skip them in screen readers
    for (const field in hostedFieldsInstance._fields) {
      const fieldFrame = hostedFieldsInstance._fields[field].frameElement
      $(fieldFrame).attr("aria-hidden", true)
    }
  }

  /*
   * PayPal
   */
  paypalCallback (clientInstance) {
    const setPaymentInfo = this.setPaymentInfo.bind(this)
    const selectPaymentType = this.selectPaymentType.bind(this)
    const billingAgreement = this.billingAgreementValue

    paypalCheckout.create({
      client: clientInstance,
    }, function (paypalCheckoutErr, paypalCheckoutInstance) {
      paypalCheckoutInstance.loadPayPalSDK({
        vault: true
      }, function () {
        window.paypal.Buttons({
          style: {
            disableMaxWidth: true,
            color: "white",
            height: 50
          },

          fundingSource: window.paypal.FUNDING.PAYPAL,

          createBillingAgreement: function () {
            return paypalCheckoutInstance.createPayment({
              // https://developer.paypal.com/braintree/docs/guides/paypal/vault
              flow: "vault",

              // The following are optional params
              billingAgreementDescription: billingAgreement,
              enableShippingAddress: false,
              shippingAddressEditable: false,
            })
          },

          onClick: function () {
            selectPaymentType(PAYMENT_TYPES.paypal)
          },

          onApprove: function (data, actions) {
            return paypalCheckoutInstance.tokenizePayment(data, function (err, payload) {
              setPaymentInfo(PAYMENT_TYPES.paypal, payload.nonce)
            })
          },

          onCancel: function (data) {
            selectPaymentType("")
          },

          onError: function (err) {
            selectPaymentType("")
          }
        }).render("#paypal-button").then(function () {
          const element = document.getElementById("paypal-button-loader")
          element.style.display = "none"

          const paypalButton = document.getElementById("paypal-button")
          paypalButton.style.display = "block"
        })
      })
    })
  }

  /*
   * Apple Pay
   */
  applePayCallback (clientInstance) {
    if (!(window.ApplePaySession && window.ApplePaySession.canMakePayments())) {
      return
    }

    applePay.create({
      client: clientInstance
    }, function (applePayErr, applePayInstance) {
      if (applePayErr) {
        console.error("Error creating applePayInstance:", applePayErr)
        return
      }

      const loader = document.getElementById("apple-pay-button-loader")
      loader.style.display = "none"

      const applePayButton = document.getElementById("apple-pay-button")
      applePayButton.style.display = "block"

      $("#apple-pay-button").click(() => this.onApplePayButtonClicked(
        applePayInstance, this.selectedPlan.upfront_amount_on_signup)
      )
    }.bind(this))
  }

  onApplePayButtonClicked (applePayInstance, totalDue) {
    const setPaymentInfo = this.setPaymentInfo.bind(this)
    const selectPaymentType = this.selectPaymentType.bind(this)
    const billingAgreement = this.billingAgreementValue

    const request = {
      countryCode: "US",
      currencyCode: "USD",
      merchantCapabilities: [
        "supports3DS",
        "supportsDebit",
        "supportsCredit"
      ],
      supportedNetworks : [
        "visa",
        "masterCard",
        "amex",
        "discover",
        "jcb"
      ],
      requiredBillingContactFields: [
        "postalAddress"
      ],
      "lineItems": [],
      "recurringPaymentRequest": {
        paymentDescription: billingAgreement,
        billingAgreement: billingAgreement,
        managementURL: "https://nadinewest.com/profile",
        regularBilling: {
          label: "Recurring Payment",
          amount: "0.00",
          paymentTiming: "recurring",
          type: "pending",
          recurringPaymentStartDate: new Date().toString()
        }
      },
      total: {
        label: "Nadine West",
        amount: totalDue
      }
    }

    // Create ApplePaySession
    const session = new window.ApplePaySession(3, request)

    session.onvalidatemerchant = (event) => {
      applePayInstance.performValidation({
        validationURL: event.validationURL,
        displayName: "Nadine West Store"
      }, function (validationErr, validationData) {
        if (validationErr) {
          console.error(validationErr)
          session.abort()
          return
        }

        selectPaymentType(PAYMENT_TYPES.apple_pay)

        session.completeMerchantValidation(validationData)
      })
    }

    session.onpaymentauthorized = event => {
      applePayInstance.tokenize({
        token: event.payment.token
      }, function (tokenizeErr, tokenizedPayload) {
        if (tokenizeErr) {
          selectPaymentType("")
          session.completePayment(window.ApplePaySession.STATUS_FAILURE)
          return
        }

        setPaymentInfo(PAYMENT_TYPES.apple_pay, tokenizedPayload.nonce)

        // Once the transaction is complete, call completePayment
        // to close the Apple Pay sheet
        session.completePayment(window.ApplePaySession.STATUS_SUCCESS)
      })
    }

    session.oncancel = event => {
      selectPaymentType("")
    }

    session.begin()
  }
}
