import { Controller } from "@hotwired/stimulus"

// Buttons instead of checkboxes
export default class extends Controller {
  static targets = [ "hiddenCheckboxes" ]
  static values = {
    checkedCountLimit: { type: Number, default: 0 }, // 0 = no limit
  }

  choose (event) {
    let button = $(event.target)
    let value = button.attr("data-value")
    if (!value) {
      // When clicking a div inside the button, the event propagates, but element won't be the correct one,
      // so get the parent:
      button = button.parent()
      value = button.attr("data-value")
    }

    if (!value) {
      return
    }

    const hiddenCheckbox = $(this.hiddenCheckboxesTarget).find('input[type=checkbox][value="' + value + '"]')

    if (button.hasClass("active")) {
      button.removeClass("active")
      button.attr("aria-checked", false)
      hiddenCheckbox.prop("checked", false)
    } else {
      if (this.checkedCountLimitValue > 0) {
        const checkedCount = $(this.hiddenCheckboxesTarget).find("input:checked").length
        if (checkedCount >= this.checkedCountLimitValue) {
          return // allow selecting up to checkedCountLimit items
        }
      }
      button.addClass("active")
      button.attr("aria-checked", true)
      hiddenCheckbox.prop("checked", true)
    }
  }
}
