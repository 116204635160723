export const WAREHOUSE_TIMEZONE = "America/Chicago"

/*
 * IMPORTANT: value will be converted to warehouse timezone, so it should only be used for timestamps
 * value: date with time as ISO 8601 string
 */
function formatTimestampAsDate(value) {
  if (value == null) {
    return ""
  }
  const dt = new Date(value)
  return dt.toLocaleString("en-US", {timeZone: WAREHOUSE_TIMEZONE, day: "numeric", year: "numeric", month: "short"})
}

/*
 * IMPORTANT: value will be converted to warehouse timezone before formatting
 * value: date with time as ISO 8601 string
 */
function formatTimestamp(value) {
  if (value == null) {
    return ""
  }
  const dt = new Date(value)
  return dt.toLocaleString("en-US", {timeZone: WAREHOUSE_TIMEZONE})
}

const formatAmount = (amount) => (`$${amount}`)

export {formatTimestamp, formatTimestampAsDate, formatAmount}
