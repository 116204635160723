import { Controller } from "@hotwired/stimulus"

// Toggles the visibility of the target elements
export default class extends Controller {
  static targets = ["toggle"];

  toggle() {
    this.toggleTargets.forEach(target => {
      target.hidden = !target.hidden
    })
  }
}
