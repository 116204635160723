import { Controller } from "@hotwired/stimulus"

// Scrolls error into view
export default class extends Controller {
  connect() {
    const alertDanger = $(".alert-danger")
    const invalidFields = $(".is-invalid")

    if (alertDanger.length) {
      alertDanger[0].scrollIntoView({ behavior: "smooth", block: "center"})
    } else if (invalidFields.length) {
      invalidFields.first()[0].scrollIntoView({ behavior: "smooth", block: "center"})
    }
  }
}
