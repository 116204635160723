import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    showOnConnect: Boolean
  }

  connect() {
    if (this.showOnConnectValue) {
      $(this.element).modal({backdrop: "static", keyboard: false})
    }
  }
}
