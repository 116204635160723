/* WEB Feel Great common*/
import $ from "jquery"
import "bootstrap"

import "@hotwired/turbo-rails"

import "core-js/stable"
import "regenerator-runtime/runtime"

/*
 * jQuery plugins
 */

import Cookies from "js-cookie"
window.Cookies = Cookies

/* eslint-disable import/first */
import sha256 from "js-sha256"
window.sha256 = sha256

// ActionCable channels
import "../../../../channels"

// Stimulus controllers
import "../../../../controllers/web"

// Making jQuery available globally to make it available in other packs and to not import it in every module
window.$ = window.jQuery = $
